/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable spellcheck/spell-checker */
import React from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ApiError } from "src/interfaces/api-error";
import parse from "html-react-parser";
import { AppContext, OTPFlow } from "src/utils/context";
import { otpSendSMS, otpSendWhatsapp } from "src/utils/elo-utils";
import OTPFederationInfo from "src/components/otp-federation-info";
import { getSubTitleStyling } from "src/utils/styling-handler";
import {
  contactLinkCountries,
  handleContactLink,
} from "src/utils/handleContactLink";
import { Country } from "src/interfaces/country";
import {
  IWithGoogleReCaptchaProps,
  withGoogleReCaptcha,
  // GoogleReCaptcha,
} from "react-google-recaptcha-v3";
// import { RecaptchaV3 } from "src/components/recaptcha-v3";

export type IOTPVerificationProps = IAuthPieceProps;

export interface IOTPVerificationState extends IAuthPieceState {
  username?: string;
  consultantDocument?: string;
  email?: string;
  phoneNumber?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string | React.JSX.Element | React.JSX.Element[];
  isSocialSigninError?: boolean;
}

export class OTPVerification extends AuthPiece<
  IOTPVerificationProps,
  IOTPVerificationState
> {
  static readonly contextType = AppContext;

  context!: React.ContextType<typeof AppContext>;

  public constructor(props: IOTPVerificationProps) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    const { username, consultantDocument, email, phoneNumber, flow } =
      this.context.otpContext;

    this.setState({
      email: email ? this.shortenEmail(email) : undefined,
      phoneNumber,
      username,
      consultantDocument,
      isSocialSigninError: flow === OTPFlow.FEDERATION_ERROR,
    });
  }

  handleVerifyRecaptcha = async (): Promise<string> => {
    const { executeRecaptcha } = (
      this.props as unknown as IWithGoogleReCaptchaProps
    ).googleReCaptchaProps;
    if (!executeRecaptcha) {
      console.info("OtpVerification recaptcha has not been loaded");

      return "";
    }
    return executeRecaptcha("OtpVerification");
  };

  private emailButton(): React.JSX.Element {
    if (this.state?.email) {
      return (
        <Button
          id="sendTemporaryPasswordEmailButton"
          onClick={async (): Promise<void> => {
            await this.onSelectSendOptions("EMAIL");
          }}
          text={`EMAIL ${this.state?.email}`}
          style={{
            letterSpacing: "1.3px",
            marginTop: "10px",
          }}
          variant={"contained"}
        />
      );
    }
    return <></>;
  }

  private canSendSMS(): boolean {
    return (
      this.state?.phoneNumber !== undefined &&
      this.state?.country &&
      otpSendSMS(this.state?.country)
    );
  }

  private canSendWhatsapp(): boolean {
    return (
      this.state?.phoneNumber !== undefined &&
      this.state?.country &&
      otpSendWhatsapp(this.state?.country)
    );
  }

  private readonly maskPhoneNumber = (phoneNumber: string): string => {
    const visibleDigits = phoneNumber.slice(-4);
    const maskedSection = phoneNumber.slice(0, -4).replace(/\d/g, "*");
    return `${maskedSection}${visibleDigits}`;
  };

  private smsButton(canSendSMS: boolean): React.JSX.Element {
    if (canSendSMS) {
      return (
        <Button
          id="sendTemporaryPasswordSMSButton"
          onClick={async (): Promise<void> => {
            await this.onSelectSendOptions("SMS");
          }}
          text={`SMS ${this.maskPhoneNumber(
            this.state?.phoneNumber as string
          )}`}
          style={{ marginTop: "24px", letterSpacing: "1.3px" }}
          variant={"contained"}
        />
      );
    }
    return <></>;
  }

  private whatsappButton(canSendWhatsapp: boolean): React.JSX.Element {
    if (canSendWhatsapp) {
      return (
        <Button
          id="sendTemporaryPasswordWhatsappButton"
          onClick={async (): Promise<void> => {
            await this.onSelectSendOptions("WHATSAPP");
          }}
          text={`WHATSAPP ${this.state?.phoneNumber}`}
          style={{ marginTop: "24px", letterSpacing: "1.3px" }}
          variant={"contained"}
        />
      );
    }
    return <></>;
  }

  public render(): React.JSX.Element {
    const canSendSMS = this.canSendSMS();
    const canSendWhatsapp = this.canSendWhatsapp();
    return (
      <Container
        company={this.state?.company}
        isLoading={this.state?.isLoading}
      >
        <div className="row" style={styles.centerRow}>
          <Typography
            variant="caption"
            style={{
              fontSize: "26px",
              marginBottom: "10px",
              fontWeight: "700",
              letterSpacing: 0,
            }}
          >
            {this.props.i18n.get("Verification")}
          </Typography>
        </div>
        {this.subTitle(canSendSMS, canSendWhatsapp)}
        <div className="row" style={styles.centerRow}>
          <div style={{ width: "328px", margin: "10px" }}>
            {this.emailButton()}
            {this.smsButton(canSendSMS)}
            {this.whatsappButton(canSendWhatsapp)}
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.verificationHelperText}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        {this.state?.email || canSendSMS ? (
          this.contactUsText(canSendSMS)
        ) : (
          <div className="row" style={styles.centerRow}>
            <div style={{ width: "300px" }}>
              <Button
                onClick={async (): Promise<void> => {
                  this.navigate("");
                }}
                text={this.props.i18n.get("Go back to Login")}
              />
            </div>
          </div>
        )}
      </Container>
    );
  }

  private getContactUsKey(canSendSMS: boolean, country: Country): string {
    switch (country) {
      case "ec":
      case "co":
      case "cl":
        return canSendSMS
          ? `ContactUs${country.toUpperCase()}`
          : `ContactUsEmail${country.toUpperCase()}`;
      case "mx":
        return "ContactUsMX";
      default:
        return canSendSMS ? "ContactUsCL" : "ContactUsEmailCL";
    }
  }

  private contactUsText(canSendSMS: boolean): React.JSX.Element {
    const country = this.state?.country;
    const isSpanish = this.state?.language === "es";

    if (isSpanish && this.state?.isSocialSigninError) {
      return (
        <div className="row" style={styles.centerRow}>
          <div style={getSubTitleStyling(country) as React.CSSProperties}>
            <OTPFederationInfo infoType="contactinfo" country={country} />
          </div>
        </div>
      );
    }
    const text = isSpanish
      ? this.props.i18n.get(
          this.getContactUsKey(canSendSMS, this.state.country)
        )
      : "";

    let contactLinkText: string;
    if (isSpanish && contactLinkCountries.includes(country)) {
      switch (country) {
        case "mx":
          contactLinkText = `Para actualizar tus datos, da clic <a href="${handleContactLink(
            country
          )}">aqui</a>.`;
          break;
        case "co":
          contactLinkText = ` o accede <a href="${handleContactLink(
            country
          )}">aquí</a>.`;
          break;
        default:
          contactLinkText = ` o accede al siguiente enlace: <a href="${handleContactLink(
            country
          )}">clic aquí</a>.`;
          break;
      }
    } else {
      contactLinkText = ".";
    }

    const parsedContactLinkText = parse(contactLinkText);

    return (
      <div className="row" style={styles.centerRow}>
        <div
          style={{
            width: "335px",
            textAlign: "center",
            margin: "10px",
          }}
        >
          <Typography
            variant="caption"
            style={{ fontSize: "16px", color: "#333", letterSpacing: "0" }}
          >
            {text}
            {parsedContactLinkText}
          </Typography>
        </div>
      </div>
    );
  }

  private async onSelectSendOptions(
    channel: "SMS" | "EMAIL" | "WHATSAPP"
  ): Promise<void> {
    try {
      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        isLoading: true,
      });
      const recaptchaToken = await this.handleVerifyRecaptcha();
      await this.props.api.tokenSend({
        country: this.state?.country as string,
        company: this.state?.company,
        clientId: this.state?.clientId,
        redirectUrl: this.state?.redirectUri,
        username: this.state?.username?.replace(/ /g, "") as string,
        document: this.state?.consultantDocument,
        channel,
        recaptchaToken: recaptchaToken,
      });

      this.context.otpContext.channel = channel;

      const previousPage = "otp-verification";
      this.setState({
        previousPage,
      });

      this.navigate("otp-token", { ...this.state, previousPage });
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleApiErrors(error: ApiError): void {
    if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else if (this.isInvalidError(error)) {
      this.showInvalidError();
    } else if (this.OTPSessionError(error)) {
      this.showOTPSessionError();
    } else {
      this.showGenericError();
    }
  }

  private isInvalidError(error: ApiError): boolean {
    return error.message
      .toLowerCase()
      .includes("user cannot perform this action");
  }

  private showInvalidError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "The user cannot perform this action"
      ),
      formHelperState: "error",
    });
  }

  private isNotFoundError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      (error.status === 404 && !message.includes("expired session")) ||
      message.includes("failed to create") ||
      message.includes("user does not exist") ||
      message.includes("username not found")
    );
  }

  private showGenericError() {
    this.showError(
      "We were unable to process your request. Please try again later"
    );
  }

  private showNotFoundError() {
    this.showError("User does not exists");
  }

  private showError(message: string) {
    this.setState({
      formHelperErrorMessage: parse(this.props.i18n.get(message)),
      formHelperState: "error",
    });
  }

  private OTPSessionError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return message.includes("expired session");
  }
  private showOTPSessionError() {
    this.showError("Expired session.");
  }

  private getSubTitleContactText(
    country: Country
  ): string | React.JSX.Element | React.JSX.Element[] {
    switch (country) {
      case "cl":
        return parse(
          // eslint-disable-next-line spellcheck/spell-checker
          `o Accede al siguiente enlace: <a href=${handleContactLink(
            country
          )}>clic aquí</a><br>`
        );
      case "co":
        return parse(
          // eslint-disable-next-line spellcheck/spell-checker
          `desde <a href=${"https://wa.me/5511930380000"}>aquí</a>.<br>`
        );
      default:
        return "";
    }
  }

  private getSubTitleVerificationMessage(
    canSendSMS: boolean,
    country: Country
  ): string {
    if (country === "co") {
      return "Verify that this account belongs to you. Click where you want to receive your verification code.";
    }
    return `We need to verify your identity. Select ${
      canSendSMS ? "where you want" : "your email"
    } to receive the verification code.`;
  }

  private subTitle(
    canSendSMS: boolean,
    canSendWhatsapp: boolean
  ): React.JSX.Element {
    const { country, email } = this.state ?? {};
    const commonTypographyStyle = { fontSize: "16px", color: "#333" };
    const emptyContact = !email && !canSendSMS && !canSendWhatsapp;

    const message = emptyContact ? (
      <>{parse(this.getSubTitleText(country))}</>
    ) : (
      <Typography variant="caption" style={commonTypographyStyle}>
        {parse(
          this.props.i18n.get(
            this.getSubTitleVerificationMessage(canSendSMS, country)
          )
        )}
      </Typography>
    );

    const extra =
      emptyContact && country !== "mx"
        ? this.getSubTitleContactInfo(country)
        : null;

    return (
      <>
        <div className="row" style={styles.centerRow}>
          <div
            style={{
              width: "335px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <Typography variant="caption" style={commonTypographyStyle}>
              {message}
            </Typography>
          </div>
        </div>
        {extra}
      </>
    );
  }

  private getSubTitleText(country: Country): string {
    switch (country) {
      case "co":
        return this.props.i18n.get("usernotfoundCO");
      case "mx":
        return this.props.i18n.get("usernotfoundMX");
      default:
        return this.props.i18n.get(
          "We were unable to find your registration details, please contact customer service."
        );
    }
  }

  private getCellPhoneContactText(
    country: Country
  ): string | React.JSX.Element | React.JSX.Element[] {
    if (country === "ec") {
      return parse("+55 11 97470-8277");
    } else {
      return parse("+55 11 93038-0000");
    }
  }

  private getSubTitleContactInfo(country: Country): React.JSX.Element {
    const contactText = this.getSubTitleContactText(country);
    const phone = this.getCellPhoneContactText(country);

    return (
      <div className="row" style={styles.centerRow}>
        <div
          style={{
            width: "335px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="caption"
            style={{ fontSize: "16px", color: "#333", padding: "16px" }}
          >
            <div>
              Por WhatsApp al número <br />
              {phone}
              <br /> {contactText}
              <br /> Disponible 24h
            </div>
          </Typography>
        </div>
      </div>
    );
  }

  public shortenEmail = (email: string): string => {
    const regex = /(\*{4,})/g;
    return email.replace(regex, "***");
  };
}

export const OTPVerificationRecaptcha = withGoogleReCaptcha(OTPVerification);
