/* eslint-disable spellcheck/spell-checker */
import React, { ReactNode } from "react";
import { styles } from "../styles/styles";
import { Logo, Image } from ".";
import {
  FlagArgentina,
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagMalaysia,
  FlagPeru,
  FlagMexico,
} from "src/assets/country-flags";
import { Typography } from "@naturacosmeticos/natds-web";
import packageJson from "../../package.json";
import { BackButton } from "./back";
import { Loading } from "./loading";
import { FlagEcuador } from "src/assets/country-flags/ecuador";

interface ContainerProps {
  country?: string;
  company?: string;
  extra?: ReactNode;
  isLoading?: boolean;
  hideBackButton?: boolean;
}

export class Container extends React.Component<ContainerProps> {
  public render(): React.JSX.Element {
    const { children, country, extra, isLoading, hideBackButton } = this.props;
    return (
      <div style={styles.containerView}>
        {hideBackButton || isLoading ? null : <BackButton />}
        <div style={{ ...styles.container, textAlign: "center" }}>
          <Logo />
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {children}
              {country && (
                <div style={{ marginTop: "15px" }}>
                  <Image
                    src={this.getCountryFlag(country)}
                    alt=""
                    maxWidth="semi"
                  />
                </div>
              )}
              {extra}
            </>
          )}
        </div>
        <div style={{ position: "fixed", bottom: 0, left: "10px" }}>
          <Typography color="textSecondary" variant="caption">
            V-{packageJson.version}
          </Typography>
        </div>
      </div>
    );
  }

  private getCountryFlag(country: string): string {
    switch (country.toLowerCase()) {
      case "my":
        return FlagMalaysia;
      case "br":
        return FlagBrasil;
      case "co":
        return FlagColombia;
      case "cl":
        return FlagChile;
      case "pe":
        return FlagPeru;
      case "ar":
        return FlagArgentina;
      case "mx":
        return FlagMexico;
      case "ec":
        return FlagEcuador;
      default:
        return "";
    }
  }
}
